.icon {
  color: #699dff;
  margin-bottom: 20px;
}

.header { 
  margin-bottom: 30px;
}

.navlink {
  font-family: Newake;
  font-size: 22px;
  line-height: 24px;
  letter-spacing: -0.1px;
  color: #999999;
  cursor: pointer;
}

.navlinkactive {
  color: #1c48ab;
}

.pill {
  font-family: Newake;
  font-size: 12px;
  color: #faebeb;
  background-color: #999999;
  border-radius: 50%;
  vertical-align: middle;
  font-weight: lighter;
  padding: .54em .75em .4em .75em;
  margin-right: 1em;
}

.pillactive {
  background-color: #1c48ab;
}

.content {
  font-size: 18px;
}

.tabtitle {
  font-family: Newake;
  font-size: 30px;
  margin-bottom: 25px;
}

.accordionHeader {
  background-color: #f7f7f7 !important;
  border-color: #f7f7f7 !important;
  box-shadow: none;
}

.hintTabs {
  margin-left: 100px;
}

.hintContent {
  margin-left: 100px;
  margin-top: 40px;
}

/* iPad */
@media only screen and (max-width: 768px) {
  .hintTabs {
    margin-left: 45px;
  }

  .hintContent {
    margin-left: 50px;
  }
}

/* tabs  */
@media (min-width: 768px) {
  .accordionHeader {
    display: none;
  }

  .accordionBody {
    display: none !important;
  }

  .accordionContent {
    display: block !important;
    background-color: #f7f7f7 !important;
    border: none;
  }
}

/* accordion mobile */
@media (max-width: 767px) {
  .content {
    display: block !important;
  }

  .hintnav { 
    display: none;
  }

  .tabtitle{
    display: none;
  }

  .tabbody { 
    display: none;
  }

  .hintTabs {
    margin-left: 25px;
  }

  .hintContent {
    margin-left: 0;
  }
}