.searchIcon {
  color: #fd9f01;
  height: 20px;
  width: 20px;
}

.searchInput {
  padding: 0;
}

.searchInput :global(.rw-combobox-input.rw-widget-input) {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #999;
  width: 100%;
  display: inline-block;
  padding-left: 20px;
  height: 50px;
  min-width: 150px;
  border-radius: 10px;
}