.cancelled {
  color: #ff4d44;
}

.completed {
  color: #00ad53;
}

.open {
  color: #699dff;
}

.bodyContainer {
  margin-top: -30px;
}

.ordersResults {
  background-color: white;
  padding-top: 20px;
  margin: 10px auto 0 auto;
  border-radius: 10px;
}

.filterContainer {
  background-color: white;
  border-radius: 10px;
  margin-left: 25px;
  min-height: 275px;
}

.centerText {
  text-align: center;
}