.surveyHeader {
  background-color: #1c48ab;
  color: #ffffff;
  padding-top: 15px;
}

.preamble {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.2px;
  padding-bottom: 50px;
}

.title {
  padding-top: 50px;
}

.terminationMessage {
  padding-top: 25px;
  padding-bottom: 200px;
}

.leftAligned {
  text-align: left;
}

.surveyAnswerLabel {
  padding-left: 10px;
}

.surveyResponseContainer {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}

.surveyContainer {
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  padding-top: 25px;
  padding-bottom: 200px;
}

.center {
  margin: auto;
}

.surveyQuestionContainer {
  margin-top: 10px;
  margin-bottom: 15px;
}

.surveyQuestionPrevious {
  margin-right: 300px;
}

.surveyQuestionButtons {
  margin: auto;
  padding: 25px 25% 0 25%;
}

.surveyQuestionButtons button {
  display: initial !important;
}

.surveyQuestionAnswersContainer {
  margin-top: 20px;
  text-align: center;
  display: inline-block;
}

.surveyResponseAnswersContainer {
  margin-top: 10px;
  text-align: center;
  display: inline-block;
}

/* iPad */
@media only screen and (max-width: 820px) { 
  .surveyQuestionPrevious {
    margin-right: 150px;
  }
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .surveyQuestionButtons {
    padding: 25px 0 0 0;
  }

  .surveyQuestionButtons button {
    min-width: 120px;
  }
}