.todocontainer{
  margin: -180px 0 80px 0;
}

.sectionHeader {
  margin-bottom: 20px;
}

.card {
  border-radius: 8px;
  border: solid 1px #dedede;
  max-width: 350px;
  min-width: 350px;
  height: 410px;
}

.cardHeader {
  background-color: #ffffff;
  margin: 10px 30px 0 20px;
}

.cardBody {
  margin: 10px 30px 10px 20px;
  overflow-y: auto;
  max-height: 250px;
}

.cardContent {
  font-size: 16px;
}

.buttonRow {
  width: 290px;
  margin-top: 40px;
  position: absolute;
  bottom: 0;
  padding-bottom: 30px;
}

.button {
  width: 290px;
  height: 50px;
  border-radius: 10px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
}

.buttonPrimary {
  border: none;
  background-color: #fd9f01;
  color: white;
}

.buttonSecondary {
  background-color: white;
  color: #fd9f01;
  border-color:  #fd9f01;
  border-width: 2px;
  border-style: solid;
}

.cardSubheaderNoBorder {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  padding-bottom: 15px;
  color: #555;
}

.cardSubheader {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px #f1f1f1;
}

.appointmentAccordion :global(.accordion-button:not(.collapsed)){
  color: unset;
}

.appointmentAccordion :global(.accordion-button::after) {
  padding-bottom: 45px;
}

.appointmentAccordion :global(.accordion-button:not(.collapsed)::after) {
  transform: rotate(180deg) translate(0px, 30px);
}

.appointmentAccordion :global(.accordion-item) {
  border: none;
}

.appointmentAccordion :global(.accordion-body) {
  padding: 0;
}

.appointmentAccordion :global(.accordion-button:not(.collapsed)) {
  box-shadow: none;
  background-color: inherit;
}

.appointmentAccordion :global(.accordion-button:focus) {
  box-shadow: none;
}

.appointmentAccordionHeader {
  color: #699dff;
}

.appointmentAccordionHeaderWrapper button {
  padding: 0;
}

.appointmentAccordionHeaderDate {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 13%;
  color: black;
}

.appointmentAccordionBody {
  margin-left: 10%;
}

.icon {
  color: #699dff;
  margin-right: 10px;
  vertical-align: text-bottom;
}

.actionIcon {
  color: #00ad53;
  margin-right: 10px;
  vertical-align: text-bottom;
}

.referralLink {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #fd9f01;
  text-align: left;
  text-decoration: none;
  padding-right: 20px;
  padding-top: 10px;
  margin: 0;
}

.actionLink { 
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #fd9f01;
  text-align: left;
  text-decoration: none;
  padding-top: 10px;
  margin: 0;
}

.orderAccordionHeaderDate {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 27px;
  color: black;
}

.followupOrderAccordionHeaderDate {
  padding-top: 10px;
  padding-bottom: 10px;
  color: black;
}

.orderInstructions {
  margin-left: 5px;
  padding-bottom: 10px;
}

.backupCard {
  max-width: 300px;
  border-radius: 8px;
  height: 410px;
  background-color: #f7f7f7;
  padding: 10px 30px;
}

.backupCardCopy {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #3a4153;
}

.backupCardCopyWrapper {
  margin-top: 15px;
  margin-bottom: 15px;
}

.backupCardCategory {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #699dff;
  margin-bottom: 15px;
  margin-top: 15px;
}

.backupCardTitle {
  font-family: Newake;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.15px;
  color: #555;
  margin-top: 20px;
}

.followupRequestAppointment div {
  justify-content: start !important;
}

.followupRequestAppointment button {
  padding: 0;
  height: 30px;
  background-color: inherit;
  color: #fd9f01;
}

.followupRequestAppointment button:hover {
  color: #0a58ca;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .todocontainer{
    padding: 0;
    width: fit-content;
    margin: -110px 0 80px 0;
  }

  .referralLink { 
    padding-right: 40px;
  }
}