.container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.nopadding {
  padding: 0;
  margin: 0;
}

.navicon {
  color: #999999;
}

.naviconactive {
  color: #699dff;
}

.navicondisabled {
  color: #dbdbdb;
}

.parentnavcontainer {
  background-color: #f7f7f7;
  border-radius: 8px 8px 0 0;
  width: 73px;
}

.parentnavfiller {
  width: 85px;
  border-right: 1px solid #cdcdcd;
}

.parentnav {
  padding: 10px;
}

.parentnavfirst {
  border-radius: 8px 8px 0 0;
}

.parentnavinactive {
  border-bottom: 1px solid #e7e7e7;
  border-right: 1px solid #cdcdcd;
  border-left: 7px solid #f7f7f7;
}

.parentnavactive {
  border-bottom: 1px solid #cdcdcd;
  border-top: 1px solid #cdcdcd;
  border-left: 7px solid #699dff;
}

.parentnavnochildrenactive {
  background-color: #ffffff;
  border-right: 1px solid #ffffff;
}

.childnavcontainer {
  background-color: #f7f7f7;
  border-radius: 8px 0 0 0;
  box-shadow: inset -3px 0 0 0 rgba(0, 0, 0, 0.14);
}

.childnavheader {
  font-family: Newake;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.15px;
  margin-top: 30px;
  border-bottom: solid 1px #e7e7e7;
}

.childnavcol {
  padding-left: 0;
}

.childnav {
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.childnavinactive {
  color: #999999;
}

.childnavactive {
  color: #699dff
}

.contentcol {
  background-color: #ffffff;
  border-radius: 0 8px 0 0;
}

.contentcolsingle {
  background-color: #ffffff;
  border-radius: 8px 8px 0 0;
}

@media screen and (max-width: 768px) {
  .parentnavcontainer {
    width: 50px;
    padding-right: 0;
  }
  .parentnavfiller {
    width: 60px;
  }
  .parentnav {
    padding: 0;
  }
  .contentcolsingle {
    padding: 0;
  }
}