.header {
  background-color: #1c48ab;
  color: #ffffff;
}

.enrollmentTerminatedText {
  padding: 10px 15px 30px 15px;
}

.salutation {
  padding-top: 100px;
  padding-bottom: 25px;
}

.intro {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.2px;
  padding-top: 25px;
  padding-bottom: 50px;
}