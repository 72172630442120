.header {
  background-color: #1c48ab;
  color: #ffffff;
}

.plan {
  font-family: Newake;
  font-size: 13px;
  line-height: 11px;
  margin-top: 25px;
}


@media (min-width: 768px) {
  .header {
    height: 550px;
  }

  .salutation {
    padding-top: 100px;
  }

  .intro {
    font-size: 18px;
    color: #ffffff;
    line-height: 30px;
    letter-spacing: -0.2px;
    padding-top: 25px;
  }
}

@media (max-width: 767px) {
  .header {
    height: 300px;
  }
  .salutation {
    padding-top: 50px;
  }

  .intro { 
    display: none;
  }
}