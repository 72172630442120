.profileText {
  height: 100px;
}

.showValidationError {
  display: block;
}

.isPublishedCheckBox {
  margin: auto;
  padding-left: 15px;
}

.header {
  font-family: Newake;
  font-size: 16px;
  margin: 20px 0;
}