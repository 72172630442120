.buttonSpacer {
  margin-top: 5px
}

.importAccordion {
  margin-bottom: 10px;
}

.importHeaderRow {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 75px;
}

.importedRowButton {
  display: flex;
  margin: auto;
}

.recordsRow {
  cursor: pointer;
}

.dataImportDragAndDropUpload div {
  padding: 0 20px;
}

.dataImportLoading { 
  padding: 0;
  height: 100%;
  width: 100%;
}

.dataImportLoading svg { 
  height: 100%;
  width: 100%;
}

.dataImportLoading rect { 
  height: 100%;
  width: 100%;
}

.tabs {
  margin-top: -10px;
}