.customToolbarAction {
  display: flex;
  margin-bottom: 6px;
  width: 25px;
  border: 1px solid #F1F1F1;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
}

.customToolbarAction:hover {
  box-shadow: 1px 1px 0px #bfbdbd;
}

.messageCenter{
  margin-top: 20px;
}

.folderlistcontainer {
  background-color: #fff;
  border-radius: 8px 0 0 8px;
}

.folderlistheader {
  background-color: #1c48ab;
  width: 100%;
  padding: 20px;
  height: 60px;
  border-radius: 8px 0 0 0;
}

.folderlistheadericon {
  color: #fff;
}

.foldericon {
  color: #1c48ab;
  margin: 0px 10px;
}

.folderlist {
  background-color: #fff;
  border-radius: 8px 0 0 8px;
  padding: 20px;
}

.folderlink {
  font-family: Newake;
  color: #203560;
  font-size: 16px;
  letter-spacing: 0.1em;
  text-decoration: none;
  padding: 5px 0;
  width: 100%;
}

.activefolderlink {
  color: #1c48ab;
  width: 100%;
  border-left: 3px solid #1c48ab;
  background-color: #f7f7f7;
}

.folderheader {
  background-color: #1c48ab;
  width: 100%;
  padding: 20px;
  height: 60px;
  border-radius: 0 8px 0 0;
}

.messagelist {
  border-radius: 0 8px 0 0;
  background-color: #fff;
  padding-bottom: 5px;
}

.unread {
  font-weight: bold;
}

.messagecontainer{
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  padding: 0 11px;
  margin: 0px;
}

.messageheader {
  background-color: #1c48ab;
  border-radius: 0 8px 0 0;
  padding: 5px;
  height: 60px;
}

.messageRow {
  padding: 8px 0px;
}

.messageRow:hover {
  background: #00000013;
}

.messageRowHeader {
  padding: 8px 0 0 0;
  font-weight: bold;
  margin-bottom: -10px
}

.messageRowLink {
  text-decoration: none;
  color: inherit;
}

.subject {
  font-family: Newake;
  font-size: 20px;
  padding: 10px 0;
}

.correspondent { 
  color: #699dff;
  margin: 0px 10px;
}

.time {
  margin-left: 50px;
  font-size: 14px;
}

.messagetext {
  font-size: 18px;
  border-top: 1px solid #1c48ab;
  padding: 20px 10px;
  margin-top: 20px;
}

.composercontainer {
  border-radius: 8px 8px;
  box-shadow: 1px 2px 2px 1px #dbdbdb;
  padding-bottom: 2px;
}

.composerwrapper {
  border-radius: 8px 8px;
  margin-top: 10px;
  padding: 10px;
  box-shadow: inset -1px -1px 2px 1px #999999, inset 1px 1px 2px 1px #999999;
}

.sendrow {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .folderlistheader {
    display: none;
  }

  .folderheader {
    border-radius: 8px 8px 0 0;
  }

  .messageheader {
    border-radius: 8px 8px 0 0;
  }

  .messagecontainer{
    border-radius: 0 0 8px 8px;
  }

}