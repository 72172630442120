.alerticon {
  color: #ff4d44;
  font-size: 16px;
}

.alertrow {
  margin: 25px 0 15px;
  border-top: solid 1px #f1f1f1;
}

.emergency {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  color: #999;
}

.header {
  background-color: #1c48ab;
  color: #ffffff;
  padding: 100px 60px; 
}

.headercopy {
  margin: 50px 0 30px 0 ;
  font-size: 18px;
}

.hospitalcard {
  margin: -50px 0 80px 0;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
}

.hospitalcardimagecol {
  padding-right: 0;
}

.hospitalcardimage {
  border-radius: 8px 0 0 8px;
  padding: 0px;
  margin: -1px 0 0 -35px;
}

.hospitalcarddetails {
  margin: 30px 0 0 30px;
}

.name {
  border-bottom: solid 1px #dbdbdb;
}

.namelink {
  font-family: Newake;
  font-size: 26px;
  color: #203560;
  text-decoration: none;
}

.address {
  margin: 10px 0 8px 0;
}

.distance {
  font-size: 14px;
  line-height: 22px;
  color: #555555;
}

.largedivider {
  border-right: 1px solid #f1f1f1;
}

.smalldivider {
  border-top: 1px solid #f1f1f1;
  margin: 10px 0;
}

.score {
  padding-bottom: 20px;
}

.helpadjustment {
  margin-top: -60px;
}

.icon { 
  color: #00b1ff;
}

.loading {
  margin-top: 30px;
}

@media screen and (max-width: 600px) {
  .header {
    padding: 60px 30px 80px; 
  }

  .hospitalcard {
    max-width: 320px;
  }

  .hospitalcardimage {
    border-radius: 8px 8px 0 0;
    padding: 0px;
    margin: -1px 0 0 -25px;
  }

}