.benefitListItem {
  height: 40px;
  min-width: 150px;
  border-radius: 10px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  background-color: white;
  border-color: #203560;
  border-width: 2px;
  border-style: solid;
}

.configHelpIcon {
  margin: auto;
}

.cancelButtonSpacer {
  margin-top: 17px
}

.editEmployeeUploadConfig {
  margin-left: 5px;
  margin-bottom: 2px;
}

/***** Shake *****/
.shake:hover{ 
  animation: shake 75ms infinite;
  animation-timing-function: linear;   
}

@keyframes shake {
  0% { transform: rotate(0.5deg); }
  50% { transform: rotate(-0.5deg); }
  100% { transform: rotate(0.5deg); }
}

.readonlyFieldDisplay {
  height: 100%;
  align-items: center;
  display: flex;
}

.showValidationError {
  display: block;
}

.planHeader {
  text-align: center;
}

.planId {
  vertical-align: sub;
  padding-left: 15px;
}

.planIntro {
  min-height: 120px !important;
}

.planBenefitPeriodDates {
  margin-top: -5px;
}

.planBenefitPeriodDatesLabel {
  margin: auto;
  padding-left: 25px;
}

.tabs {
  margin-top: -10px;
}

@media only screen and (max-width: 600px) {
  .uploadEmployeesFileButton {
    padding: 20px;
  }

  .planId {
    display: block;
    padding-left: 0;
    font-size: 15px;
    white-space: pre;
    text-align: initial;
  }
}