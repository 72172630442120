.percentile {
  height: 20px;
}

.percentileOne {
  background-color: #006531;
  border-radius: 3px 0 0 3px;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  width: 47%;
}

.percentileTwo {
  background-color: #009046;
  width: 25%;
}

.percentileThree {
  background-color: #00b658;
  width: 25%;
}

.percentileFour {
  background-color: #00d768;
  border-radius: 0 3px 3px 0;
  width: 3%;
}

.table {
  border-collapse: collapse;
  border-spacing: 0;
  align-self: center;
  min-width: 175px;
}

.nopadding {
  margin: 0;
  padding: 0;
}

.trianglecell {
  margin: 0;
  padding: 0;
  line-height: 15px;
  text-align: center;
  font-size: 12px;
}

.triangle {
  width: 2px;
  margin: -5px;
  padding: -1px;
}

.whaticon {
  color: #1c48ab;
  margin-right: 5px;
}

.what{
  margin-top: 15px;
  color: #555555;
  font-size: 12px;
  text-decoration: underline;
}

.tip {
  cursor: pointer;
}