.appointmentTime label {
  margin-right: 10px;
}

.appointmentTime {
  display: flex;
}

.secondaryProblems label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.secondaryProblemsContainer :global(.rw-multiselect-taglist){
  padding-top: 25px;
}

.chiefComplaintValidationError {
  display: block;
}

.requestAppointmentSubmit {
  width: 675px
}

.disclaimerIcon {
  text-align: center;
  border-bottom: 1px solid lightgray;
  min-width: 655px;
  margin-left: -20px;
}

.disclaimerIcon svg {
  position: relative;
  margin-bottom: -20px;
  z-index: 100;
  color: #fd9f01 !important;
}

.disclaimerHeader {
  text-align: center;
  margin-top: 35px;
  margin-bottom: 10px;
}

.disclaimerHeader h5 {
  color: #1c48ab;
}

.disclaimerText {
  margin-bottom: 20px;
  text-align: center;
}

.viewAppointmentDisclaimer .disclaimerIcon {
  min-width: unset;
  margin-left: unset;
}

.requestAppointmentSubmit button {
  min-height: 75px;
  font-size: 20px;
}

.header {
  margin-top: 50px;
  margin-left: 10px;
}

.headerIcon {
  margin-bottom: 20px;
}

.headerIcon span {
  color: rgb(0, 177, 255);
  font-family: Newake;
  font-size: 15px;
  font-weight: lighter;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.5px;
}

.preferredDateContainer {
  height: 58px;
}

.calendarPopover {
  max-width: 335px !important;
  margin-top: 45px;
}

.additionalNotesTextArea textarea {
  min-height: 120px !important;
  margin-top: 5px;
}

.preferredDateDropdown :global(.rw-dropdown-list-value) {
  padding-top: 20px;
}

.preferredDateDropdown input {
  padding-top: 20px;
}

.preferredDateDropdown label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.requestSentModalBody {
  padding: 10px 40px 10px 40px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #555;
}

.requestSentModalBody svg {
  color: #00B1FF; 
}

.requestSentModalRow {
  padding-bottom: 5px;
}

.closeModalIcon {
  color: #999999 !important;
}

.additionalNotes {
  padding: inherit;
  margin-top: 5px;
  color: #999999;
  font-size: 14px;
  font-family: "Open Sans",sans-serif, Newake;
}

.inheritPadding {
  padding: inherit;
}

.requestSentPharmacy {
  display: block;
}

.requestSentPharmacy {
  font-size: 14px;
  color: #999999;
}

.requestSentPharmacyHeader {
  font-size: 16px;
  color: #555;
}

.notificationRadio {
  display: flex;
  justify-content: center;
  margin: auto;
}

.notificationRadio input {
  /* margin-left: 10px; */
  margin-right: 5px;
}

.notificationHeader {
  margin-bottom: 0px;
  margin-top: 5px;
}

.notificationLabel {
  margin-top: 5px;
  padding-top: 10px;
}

.notificationInput input {
 font-size: 1rem;
}

.notificationLabel {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(-1.75rem);
  padding: 0;
  height: 15px;
}

.textMessageField {
  height: 58px;
  width: 100%;
  padding-top: 25px;
}

.textMessageLabel label {
  opacity: .65;
  transform: scale(.85) translateY(0.5rem) translateX(3.75rem);
  padding: 0;
}

.showTextMessageValidationError {
  display: block;
  margin-left: 40px;
}

.capitalizeTextSelect select {
  text-transform: capitalize;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .requestAppointmentSubmit {
    width: 100%;
    margin: auto;
  }

  .disclaimerIcon {
    min-width: 390px;
  }
}