body {
  margin: 0;
  font-family: "Open Sans",sans-serif, Newake;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

a.dropdown-item {
  color: #3a4153 !important;
  font-size: 14px !important;
}

.App {
  text-align: center;
}


@font-face {
  font-family: Newake;
  src: local(Newake),
    url(shared/fonts/newake.woff2) format("woff2"),
    url(shared/fonts/newake.woff) format("woff"),
    url(shared/fonts/newake.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

h1 {
  color: #203560;
  font-family: Newake;
  font-size: 80px;
  line-height: 80px;
}
h2 {
  color: #203560;
  font-family: Newake;
  font-size: 50px;
}
h3 {
  color: #203560;
  font-family: Newake;
  font-size: 34px;
}
h4 {
  color: #203560;
  font-family: Newake;
  font-size: 26px;
}
h5 {
  color: #203560;
  font-family: Newake;
  font-size: 18px;
}

h6 {
  color: #203560;
  font-family: Newake;
  font-size: 14px;
}

.inverse {
  color: #ffffff;
}

@media screen and (max-width: 600px) {
  h1 {
    font-size: 38px;
    line-height: 40px;
  }
  h2 {
    font-size: 38px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 24px;
  }
}


/* to debug bootstrap grid */
/*
[class*="row"] { border: solid 1px #000; }
[class*="col"] { border: solid 1px red; } 
*/