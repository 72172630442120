.navigation {
  background-color: #ffffff;
}
.avatar { 
  color: #72798A;
}

.navbar-brand {
  color: #3a4153 !important;
}
.navbar-nav .nav-link {
  color: #3a4153 !important ;
}
a.nav-link:hover {
  color: #3a4153 !important;
}
a.nav-link > svg {
  font-size: 20px;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: #6e859c !important;
}

@media (max-width: 600px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1.8rem !important;
    padding-left: 0.5rem;
    text-align: center;
    justify-content: left;
    display: flex;
    flex-direction: row;
    border: 1px solid #6e859c;
  }
}