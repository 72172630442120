.categoryIcon {
  color: #699dff;
  margin-right: 10px;
  vertical-align: text-bottom;
}

.categoryTitleRow
{
  margin-top: 30px;
}

.categoryTitle {
  font-family: Newake;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #699dff;
  margin-bottom: 25px;
}

.perkCard {
  max-width: 300px;
  height: 330px;
}

.perkNavigator {
  max-width: 300px;
}

.perkTitle {
  font-family: Newake;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: -0.15px;
  color: #555;
  margin-top: 20px;
}

.teaser {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  color: #3a4153;
  margin: 25px 0 25px; 
}

.learnMore {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #fd9f01;
  text-align: left;
  text-decoration: none;
  margin-left: -10px
}

.offCanvas {
  width: 40%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  overflow-y: auto
}

.canvasBody {
  margin: 10px 50px;
  overflow-y: initial;
}

.canvasImage {
  margin: 10px 0 30px 0;
  border-radius: 8px;
}

.canvasTitle {
  margin: 0 0 20px 0;
}

.closeOffCanvas {
  font-size: 24px;
  text-decoration: none;
  color: #555;
}

.canvasCopy > h5 {
  margin: 20px 0;
}

.canvasCopy > p {
  margin: 10px 0;
}

:global(.rec.rec-dot_active) {
  background-color: #1c48ab;
  box-shadow: 0 0 1px 3px #1c48ab;
}

.navigatorButton {
  border: none;
  background-color: transparent;
}

.navigatorIcon {
  color: #555
}


@media (max-width: 767px) {
  .offCanvas {
    width: 100%;
  }
}