.casesResults {
  background-color: white;
  padding-top: 20px;
  margin: 10px auto 0 auto;
  border-radius: 10px;
}

.centerText {
  text-align: center;
}

.plainTextLink {
  color: inherit !important;
  text-decoration: none;
}

.filterContainer {
  background-color: white;
  border-radius: 10px;
  margin-left: 25px;
  min-height: 275px;
}

.filterCheckBox {
  float: left;
  width: auto;
  padding-right: 0px;
}

.filterRow {
  flex-wrap: nowrap;
  height: 24px;
}