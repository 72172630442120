.lineItem {
  margin-top: 10px;
}

.readOnlyInput {
  width: 100%;
  margin: 5px;
  padding: 0;
  background: transparent;
  border: none;
  outline: none
}

.addressRadioButton input{
  width: 100%;
  margin-top: 15px;
}

.phoneField {
  height: 58px;
  width: 100%;
}

.missingAccountEmailIcon {
  color: #ff4d44;
  margin-left: 10px;
}

.phoneIcon {
  color: #1c48ab
}

.addEmailSubmit { 
  display: flex;
  justify-content: flex-end !important;
  margin-top: 10px;
}

.showPhoneValidationError {
  display: block;
  margin-left: 40px;
}

.showValidationError {
  display: block;
}

.phoneDropdownContainer {
  height: 58px;
}

.phoneDropdown :global(.rw-dropdown-list-value) {
  padding-top: 20px;
}

.phoneDropdown input {
  padding-top: 20px;
}

.phoneDropdown label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
}

.showPhoneDropdownValidationError {
  display: block;
}