.careCenters {
  margin-top: 100px;
}

.title {
  margin: 30px 0 0 0;
  padding-left: 20px;
  display: flex;
}

.header {
  background-color: #1c48ab;
  color: #ffffff;
  padding: 60px 60px; 
}

.container {
  margin-top: -5px;
}

.enrollmentinfocontainer {
  border: 1px solid #1c48ab;
  border-radius: 8px;
  padding: 0px;
}

.enrollmentinfoheader {
  background-color: #1c48ab;
  color: #ffffff;
  border-radius: 8px 8px 0 0;
  padding: 10px 0;
  margin: 0px;
  width: 100%;
}

.enrollmentinfo {
  margin: 5px 10px;
}

@media screen and (max-width: 768px) {
  .header {
    padding: 30px 30px; 
  }

  .title {
    padding: 0;
    margin: 10px 10px;
  }

  .enrollmentinfocontainer {
    margin-top: 20px;
  }
}