.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #3a4153;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #00B1FF;
  color: #00B1FF;
}

.dropzone:hover {
  border-color: #fd9f01;
  color: #fd9f01;
}

.dropzone.disabled {
  opacity: 0.6;
}

.instructions {
  margin: 10px;
}

.mediaCard {
  width: 355px;
  margin: 5px;
}