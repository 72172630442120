.accordionHeaderWrapper button::after {
  display: none;
}

.accordionHeaderWrapper button {
  pointer-events: none;
  background-color: #fafbfc !important
}

.accordionSearch :global(.accordion-item){
  border: none;
}

.biccLogo {
  margin-right: 20px;
}

.bottomBorder {
  border-top: solid 1px #f1f1f1;
  width: 250px;
}

.couponBody {
  display: block;
  height: 231px;
  border-top: solid 1px #f1f1f1;
  border-bottom: solid 1px #f1f1f1;
  margin: 0;  
  font-family: Newake;
}

.couponBodyRow {
  text-align: left;
  padding: 0;
  margin-bottom: 10px;
}

.couponBodyLabel {
  padding-top: 5px;
  padding-left: 160px;
  padding-right: 0px;
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #999;
}

.couponBodyValue {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: normal;
  color: #555;
  padding-right: 125px;
  padding-left: 0px;
}

.couponBodyPrice {
  font-family: Newake;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.92;
  letter-spacing: -0.5px;
  color: #203560;
  margin: 20px auto;
}

.couponContainer {
  max-width: 500px;
  height: 373px;
  margin: -90px auto 0px;
  padding: 20px 0 15px;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
  text-align: center;
}

.couponDrug {
  font-family: Newake;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.2px;
  color: #203560;
  margin-bottom: 10px;
}

.couponDrugDetails {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #555;
}

.couponFooter {
  display: block;
  height: 52px;
  padding-top: 12px;
}

.couponGoodRxLogo {
  width: 60px;
  height: 25px;
}

.couponHeader {
  display: block;
  text-align: left;
  margin-left: 40px;
  height: 68px;
}

.couponPrescriptionReminderWrapper {
  margin: -160px auto 110px;
  border-radius: 8px;
  width: 335px;
  height: 85px;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #dedede;
  text-align: center;  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.couponPrescriptionReminder {
  margin: -160px auto 110px;
  border-radius: 8px;
  width: 335px;
  height: 85px;
  padding: 5px;
  background-color: #fff;
  border: solid 1px #dedede;
  text-align: center;  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.couponPrescriptionReminder svg {
  color: #ff4d44
}

.detailsMenu { 
  width: 250px;
  margin: -60px 0 80px 25%;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fafbfc !important;
}

.detailsMenuNameWrapper {
  width: auto;
  height: 65px;
  padding: 20px;
  margin: 0;
  border-radius: 8px;
  border-bottom: solid 1px #dedede;
  background-color: #fff;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  display: none;
}

.detailsMenuHeader {
  width: 136px;
  height: 16px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #555;
  padding-top: 10px
}

.detailsMenuDropdown :global(.rw-widget-input.rw-widget-picker.rw-widget-container) {
  width: 100%;
  height: 67px;
  padding: 30px 35px 0px 15px;
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #999;
  background-color: #fafbfc;
  border: none;
  border-radius: unset;
}

.detailsMenuDropdownLabel :global(.form-floating>label) {
  top: 15px;
  left: 30px;
  padding: 0;
  font-family: Newake;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #555;
}

.detailsMenuSearch :global(.rw-widget-picker.rw-widget-container) {
  height: 45px;
  width: 190px;
  margin: auto;
}

.detailsMenuSearch input {
  height: 45px !important;
}

.goodRxSearchIcon {
  color: #fd9f01;
  height: 20px;
  width: 20px;
}

.goodRxInput {
  padding: 0;
}

.goodRxLogoContainer { 
  padding:10px 50px 10px 50px
}

.goodRxLogo {
  width: 96px;
  height: 40px;
  display: block;
  margin: auto;
}

.goodRxInput :global(.rw-combobox-input.rw-widget-input) {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.89;
  letter-spacing: normal;
  color: #999;
  width: 100%;
  display: inline-block;
  padding-left: 20px;
  height: 50px;
  min-width: 150px;
  border-radius: 10px;
}

.header {
  background-color: #1c48ab;
  color: #ffffff;
  padding: 100px 60px; 
  height: 350px;
}

.helpAdjustment {
  margin-top: 0px; 
}

.helpComponent {
  margin: 45px 0;
}

.helpSection {
  margin: 10px 10px;
}

.helpSectionContainer {
  justify-content: center !important;
  margin: auto;
  display: block;
}

.helpRow {
  padding: 0 200px;
}

.modalIcon {
  color: #699dff;
}

.copy {
  font-size: 18px;
}

.contactButton { 
  width: 320px;
  height: 60px;
  margin: auto;
  text-decoration: none;
}

.leftBorder {
  border-left: solid 1px #f1f1f1;
}

.lowestPricesRow {
  border-bottom: solid 1px #dbdbdb;
  height: 49px;
  text-align: center;  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding: 15px 20px;
  margin: auto;
}

.lowestPricesRow svg {
  margin-right: 5px;
  color: #699dff;
}

.lowestPricesRow a {
  text-decoration: underline !important;
}

.lowestPricesNear {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  color: #999;
  padding: 0;
  padding-right: 30px;
}

.lowestPricesNearLocation {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #699dff;
  margin-left: -42%;
}

.newDrugSearchHeader {
  margin-top: 25px;
}

.prescription {
  width: 780px;
  height: 141px;
  margin: -60px 0 0 -50px;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
}

.prescriptionName {
  width: 271px;
  height: 50px;
  margin: 27px 168px 3px 15px;
  font-family: Newake;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #203560;
}

.prescriptionSearch {
  width: 250px;
  height: 40px;
  margin: 34px auto;
}

.prescriptionSearch input {
  height: 40px !important;
}

.prescriptionResultsError {
  width: 780px;
  height: 420px;
  margin: 20px 0 0 -50px;
  padding: 165px 140px;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
  font-family: Newake;
  font-size: 34px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.25px;
  text-align: center;
  color: #ff4d44;
}

.prescriptionResultsEmpty {
  width: 780px;
  height: 420px;
  margin: 20px 0 0 -50px;
  padding: 165px 140px;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
}

.prescriptionResults {
  width: 780px;
  max-height: 738px;
  margin: 20px 0 0 -50px;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
  overflow-y: auto;
  display: block;
}

.prescriptionResultInitialText {
  width: 500px;
  height: 90px;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
  color: #203560;
}

.prescriptionName {
  width: 271px;
  height: 50px;
  margin: 27px 168px 3px 15px;
  font-family: Newake;
  font-size: 50px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.5px;
  color: #203560;
}

.prescriptionReminder {
  border-bottom: solid 1px #dbdbdb;
  height: 85px;
  text-align: center;  
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding: 15px 20px;
  margin: auto;
}

.prescriptionReminderLine1 {
  font-weight: 600;
  color: #203560;
}

.prescriptionReminderLine2 {
  color: #3a4153;
}

.prescriptionReminder svg {
  color: #ff4d44
}

.resultsRow {
  height: 119px;
  padding: 30px;
  border: solid 1px #f1f1f1;
  --bs-gutter-x: 0;
}

.resultsPharmacy {
  width: 250px;
  height: 24px;
  margin: auto;
  font-family: Newake;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.09;
  letter-spacing: -0.1px;
  color: #555;
}

.resultsCol {
  margin: auto;
}

.dollarSign {
  font-size: 20px;
  margin: 5px 2px 5px 0;
  padding: 0;
  vertical-align: middle;
}

.getCouponButton {
  width: 163px;
  height: 60px;
}

.learnMoreButton {
  width: 163px;
  height: 60px;  
  border: solid 1px #555;
  background-color: #fff;
  color: #555;
}

.learnMoreButton:hover {
  color: #555;
}

.learnMorePopover {
  max-width: 545px;
  height: auto;
}


.prescriptionResultsLoading{
  width: 780px;
  height: 420px;
  margin: 20px 0 0 -50px;
  border-radius: 8px;
  border: solid 1px #dedede;
  background-color: #fff;
}

.loadingContainer {
  padding: 0;
  height: 100%;
  width: 100%;
}

.loadingContainer svg { 
  height: 100%;
  width: 100%;
}

.loadingContainer rect { 
  height: 100%;
  width: 100%;
}

.resultsRetailPrice {
  width: 100px;
  height: 22px;
  margin: 0 0 2px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.57;
  letter-spacing: normal;
  text-align: center;
  color: #000;
  text-decoration:line-through;
}

.resultsSavings {
  width: 66px;
  height: 16px;
  margin: 2px 2px 0;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  color: #203560;
}

.resultsPrice {
  display: block;
  width: 73px;
  height: 30px;
  font-family: Newake;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  text-align: center;
  color: #555;
}

.resultsPriceSubScript {
  width: 103px;
  height: 13px;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: #555;
}

.updateResultsBtnWrapper {
  margin: 20px 30px 39px 30px;
  background-color: #fafbfc;
}

.updateResultsBtn {
  width: 190px;
  height: 45px;
  border-radius: 4px;
}

.notInsuranceDisclaimer {
  margin-top: 40px;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #3a4153;
  font-variant: small-caps;
}

.notInsuranceDisclaimer svg {
  color: #ff4d44;
  margin-right: 8px;
}

.detailsMenu :global(.accordion-item) {
  border: none;
  border-bottom: solid 1px #dedede;
  background-color: #fafbfc !important;
}

:global(.rw-picker-btn) {
  margin-left: -25px !important;
}

/* disable accordion on bigger than iPad */
@media (min-width: 768px) {
  .detailsMenu :global(.accordion-collapse.collapse:not(.show)) {
    display: block;
    height: auto !important;
    visibility: visible;
  }
}

/* iPad Pro */
@media only screen and (max-width: 1400px) {
  .detailsMenu {
    margin: -60px 0 80px -5%;
  }

  .prescription,
  .prescriptionResults {
    width: 675px;
  }

  .prescriptionResultsEmpty {
    width: 675px;
  }

  .helpSectionContainer {
    max-width: 450px;
    margin: 0 auto;
  }

  .helpRow {
    padding: 0;
  }
}

/* iPad */
@media only screen and (max-width: 768px) {
  .detailsMenu {
    margin: -60px 0 80px -10%;
  }

  .detailsMenuNameWrapper {
    display: block;
  }
  
  .detailsMenuSearch {
    margin: 10px auto;
  }

  .lowestPricesNearLocation {
    margin-left: -25%;
  }

  .prescription {
    display: none;
  }

  .prescriptionResults {
    width: 435px;
    margin: -60px 0px 0px 0%;
  }

  .prescriptionResultsEmpty {
    width: 435px;
    height: 605px;
    margin: -60px 0px 0px 0%;
    padding: 115px 75px;
  }

  .prescriptionName {
    width: 73px;
    height: 26px;
    margin: 0 207px 15px 0;
    font-size: 26px;
  }

  .resultsPharmacy {
    width: 125px;
    padding-left: 10px
  }
  
  .resultsCol {
    margin: auto;
  }

  .resultsRow {
    padding: 0;
  }

  .learnMoreButton,
  .getCouponButton {
    width: 100px ;
    padding: 0;
    min-width: 100px;
  }

  .resultsPrice,
  .resultsPriceSubScript {
    margin: auto;
  }
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .contactButton { 
    width: 300px;
  }

  .couponContainer {
    max-width: 320px;
    margin: -50px auto 0px;
  }

  .couponBodyLabel {
    padding-left: 80px;
  }

  .couponBodyValue {
    padding-right: 50px;
  }

  .couponHeaderSlogan {
    padding: 0 50px;
  }

  .couponPrescriptionReminder {
    width: 320px;
    height: 90px;
    margin: -80px auto 65px;
  }

  .detailsMenu {
    margin: -60px auto;
    width: 320px;
  }

  .header {
    padding: 75px 0px; 
  }

  .helpAdjustment {
    margin-top: 20px; 
  }

  .helpSectionContainer {
    max-width: 325px;
  }

  .detailsMenuSearch {
    width: 260px;
    margin: 10px auto;
  }

  .detailsMenuSearch :global(.rw-widget-picker.rw-widget-container) {
    height: 45px;
    width: 260px;
    margin: auto;
  }

  .header {
    height:initial;
  }

  .learnMorePopover {
    max-width: 360px;
    font-size: 13px;
  }

  .lowestPricesRow {
    height: 66px;
  }

  .lowestPricesNear {
    padding-right: 0px;
  }

  .lowestPricesNearLocation {
    margin-left: 0%;
  }

  .prescriptionReminder {
    height: 117px;
  }

  .updateResultsBtn {
    width: 280px;
    margin: 20px auto;
  }

  .updateResultsBtnWrapper {
    margin: 0;
  }

  .accordionHeaderWrapper button::after {
    display:initial;
  }

  .accordionHeaderWrapper button {
    pointer-events:initial;
  }

  .prescriptionResults {
    max-height: 1300px;
    width: 320px;
    margin: 80px auto 0px auto;
  }

  .prescriptionResultsEmpty {
    display: none;
  }

  .resultsRow {
    height: 220px;
    padding: 20px 30px;
  }

  .resultsPharmacy {
    width: inherit;
  }

  .learnMoreButton,
  .getCouponButton {
    width: 255px;
    padding: 0;
    min-width: 100px;
  }
}