.helpadjustment {
  margin-top: -60px;
}

.header {
  background-color: #1c48ab;
  color: #ffffff;
  padding: 100px 60px; 
}

.headercopy {
  margin: 50px 0 30px 0 ;
  font-size: 18px;
}

.hospitalcarddetails {
  margin: 15px 0 0 30px;
}

.name {
  border-bottom: solid 1px #dbdbdb;
}

.namelink {
  font-family: Newake;
  font-size: 26px;
  color: #203560;
  text-decoration: none;
}

.address {
  margin: 10px 0 8px 0;
}

.distance {
  font-size: 14px;
  line-height: 22px;
  color: #555555;
}

.requestAppointmentForm {
  padding: 0 30px;
}

.requestAppointmentForm div {
  background-color: initial !important;
}

.requestAppointmentForm textarea {
  min-height: 120px !important;
  margin-top: 5px;
}

.requestAppointmentSubmit {
  position: fixed;
  bottom: 0;
  width: 675px
}

.requestAppointmentSubmit button {
  min-height: 75px;
  margin-left: -30px;
  font-size: 20px;
}

.requestSentModalBody {
  padding: 10px 25px 10px 40px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: #555;
}

.requestSentModalBody svg {
  color: #00B1FF; 
}

.requestSentModalRow {
  padding-bottom: 5px;
}

.closeModalIcon {
  color: #999999 !important;
}

.additionalNotes {
  padding: inherit;
  margin-top: 5px;
  color: #999999;
  font-size: 14px;
  font-family: "Open Sans",sans-serif, Newake;
}

.inheritPadding {
  padding: inherit;
}

.careCenterAddress {
  color: #999999;
  font-size: 14px;
}

.datePickerPopover {
  right: 5px !important;
  bottom: 0px !important;
  transform: unset !important;
  top: unset !important;
  left: unset !important;
  max-width: 875px !important;
  max-width: 875px !important;
  max-height: 400px !important;
  min-height: 400px !important;
}

.preferredDatesLabel :global(.form-floating>label) {
  top: 7px;
  left: 13px;
  padding: 0;
  font-size: 13.5px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
  opacity: .65;
}

.preferredDatesLabel :global(.rw-widget-input) {
  height: 58px;
}

.preferredDatesLabel :global(.rw-dropdown-list-input) {
  margin-top: 20px;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .requestAppointmentForm {
    padding-bottom: 100px;
  }

  .requestAppointmentSubmit {
    width: 100%;
  }

  .datePickerPopover {
    position: fixed;
    top: 0 !important;
    left: 0 !important;
    transform: unset !important;
    right: unset !important;
    bottom: unset !important;
    max-width: 100% !important;
    min-width: 100% !important;
    max-height: 100% !important;
    min-height: 100% !important;
  }

  .datePickerPopover :global(.rdrCalendarWrapper) {
    display: grid;
  }

  .datePickerPopover :global(.rdrMonths) {
    margin: 0 auto;
  }
}