.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  background-color: black;
  margin-right: 10px;
}

.orange {
  background-color: orange;
}

.red {
  background-color: red;
}

.green {
  background-color: green;
}

.titleContainer {
  text-align: center;
  display: block;
  padding: 18px 0;
}

.statusText {
  font-family: Newake;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
  color: #999999;
}

.tabs {
  margin-top: -10px;
}

.container {
  height: 100%;
  margin: 0;
  padding: 0;
}

.titlecontainer {
  border-bottom: solid 1px #dbdbdb;
}

.sidebarcontainer {
  background-color: #f7f7f7;
  box-shadow: -3px 1px 5px 0px #dbdbdb;
}

.sidebarcard {
  margin: 20px 10px;
}

.separator { 
  border-bottom: solid 1px #999999;
  margin-top: 30px;
}

.sidebarlink {
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: -.8px;
}

.lightheading {
  color: #999999;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -.8px;
}

.scrollable {
  overflow-y: scroll;
  max-height: 70vh;
  padding-top:20px;
}

.tag { 
  font-weight: bold;
  color: #203560;
}

.tags { 
  margin: 10px 0 0 0;
  padding: 0;
  width: 100%;
}

.tagremove {
  text-decoration: none;
  color: #203560;
  padding: 0 1px 0 1px;
  height: 25px;
  background-color: #dbdbdb;
  box-shadow: 1px -1px 2px 0px #999999;
}

.contextLink {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #fd9f01;
  text-align: left;
  text-decoration: none;
}

.filterheading {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -.8px;
  margin-right: 10px;
}

.filteroptions {
  max-width: 300px;
}

.addNewButtons {
  margin-top: 20px;
  border: 2px solid #00b658;
  border-radius: 0.5rem;
  font-family: Newake;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
}

.messageComposer :global(.container) {
  box-shadow: none !important;
}

.messageTagsList {
  max-width: fit-content;
  margin-top: 10px;
}

.messageComposerTagsList {
  max-width: fit-content;
}

.messageComposerIconWrapper{
  margin-right:25px;
}

.newButtonLabel {
  padding: 15px;
  color: #999999;
}

.newButton {
  padding: 15px;
  color: #00b658;
  border-left: 1px solid lightgrey;
  display: flex;
  cursor: pointer;
}

.newButton:hover {
  background-color: lightgrey;
}

.newButton svg {
  color: #999999;
  margin-right: 5px;
}

.messageActions {
  text-align: end;
}

.messageActions svg {
  color: #999999;
  margin-left: 10px;
}

.messageActions svg:hover{
  color: #fd9f01;
}

.messageBody svg {
  margin-right: 10px;
}

.messageSender {
  color: blue;
  margin-right: 40px;
}

.messageTime {
  color: #999999;
  font-size: 14px;
  letter-spacing: 0px;
}

.messageHeader {
  display: flex;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: 1px;
}

.messageThreadType {
  text-align: center;
}

.auditIcon { 
  color: #00b658;
}

.noteIcon {
  color: #fd9f01;
}

.messageIcon {
  color: #00B1FF;
}

.messageReplyContainer {
  padding-top: 20px;
  justify-content: flex-end;
  display: flex;
}

.messageReplyContainer :global(.container) {
  padding: 0;
}

.messageReplyIconWrapper {
  width: 5%;
  padding: 0;
  justify-content: flex-end;
  display: flex;
}

.messageReplyIcon {
  transform: rotate(180deg);
  color: #999999;
  justify-content: center;
  text-align: center;
}

.quotedText {
  background-color: lightgrey;
  font-style: italic;
  margin-top: 10px;
  border-bottom: 2px dotted black;
  padding-bottom: 10px;
}

.quotedText p {
  margin: 0;
}

.contextViewAccordion {
  padding: 0;
}

.contextViewAccordionHeader button {
  padding: 5px 5px 5px 0;
  background-color: unset;
}

.contextViewAccordion :global(.accordion-item) {
  border: none;
  background-color: unset;
}

.contextViewAccordion :global(.accordion-button:not(.collapsed)) {
  background-color: unset;
  box-shadow: unset;
}

.contextViewAccordion :global(.accordion-button:focus) {
  box-shadow: unset;
}

.viewOrderAccordionBody {
  padding: 0;
}

.viewOrderHeader {
  padding: 10px 0px 5px 14px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
}

.viewOrderLink {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: unset;
  text-align: left;
  text-decoration: none;
}

.viewOrderDate {
 color: #00ad53;
 margin-right: 10px;
 margin-left: 13px;
}

.viewOrderProvider {
  color: red;
  margin-right: 10px;
  margin-left: 13px;
}

.viewOrderCareCenter {
  color: #699dff;
  margin-right: 10px;
}

.viewAppoinmentRequestBody div{
  padding: 0 0 0 7px !important;
}

.viewAppoinmentRequestBody, :global(.accordion-body) {
  padding: 0 !important;
}

.addCaseTags {
  display: block;
}

.addCaseTags button {
  margin-top: 5px;
  display: flex;
}

.addCaseInitialNote {
  margin-bottom: 20px;
  margin-top: 20px;
}

.addCaseInitialNote textarea {
  min-height: 90px;
}

.addCaseSubmit {
  margin-top: 20px;
}

.closeicon {
  color: #999999;
}