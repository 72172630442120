
.card {
  padding: 5px;
  box-shadow: 1px 1px 1px 1px #1c48ab;
  border-radius: 8px;
}

.available {
  position: relative;
  top: 0.5em;
}