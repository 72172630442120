.policy {
  background-color: #ffffff;
  padding: 50px;
}

.policyHeader {
  margin: 50px 0 0  0;
}

.policySpacer {
  height: 20px;
}