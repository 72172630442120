.headerRow {
  display: "flex";
  justify-content: left;
  margin: 10px 10px 5px auto;
}

.inputRow {
  display: "flex";
  justify-content: normal;
  margin: 10px;
}

.input {
  background-color: #f7f7f7 !important;
  margin: 5px auto;
  border-radius: 0.3rem;
}