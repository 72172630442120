.pharmacySelectorAccordion {
  margin-left: -35px;
  margin-right: -35px;
  margin-top: 25px;
  margin-bottom: 5px;
  position: relative;
}

.pharmacySelectorAccordion :global(.accordion-item) {
  background-color: #335fc0;
}

.pharmacySelectorAccordion button {
  background-color: #1c48ab;
  color: white;
  padding: 20px;
}

.pharmacySelectorAccordion :global(.accordion-button::after) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.pharmacySelectorAccordion button:focus {
  border-color: unset;
  box-shadow: unset;
}

.pharmacySelectorAccordion :global(.col) {
  padding: 0;
}

.pharmacySelectorAccordion button:not(:global(.collapsed)) {
  background-color: #1c48ab;
  color: white;
}

.pharmacySelectorAccordion button span {
  padding: 10px 20px;
}

.pharmacySelectorAccordion input {
  font-size: 1.0rem;
}

.pharmacyLabel span {
  margin-top: 20px;
  display: inline-block;
  padding-left: 10px;
  color: #233362;
}

.pharmacyCurrentSearch {
  max-height: 435px;
  margin-top: 10px;
  color: white;
  padding-left: 0;
}

.pharmacyCurrentSearch span {
  display: block;
  font-family: Newake;
  font-weight: 500;
  font-size: 12px;
}

.pharmacyCurrentSearchAddress {
  padding-top: 5px;
  opacity: 0.75;
}

.pharmacySearchResults {
  max-height: 435px;
  margin-top: 10px;
  color: white;
  padding-left: 45px;
  display: block;
  font-family: Newake;
  font-weight: 500;
  font-size: 12px;
}

.displayPharmacyAddress {
  padding-left: 5px;
  display: inline;
  margin-top: 20px;
  color: #233362;
}

.selectablePharmacy {
  color: white;
  margin: 5px 10px;
  padding: 15px 20px 15px 35px;
  cursor: pointer;
  background-color: #233362;
  width: 590px;
  border-radius: 0.3rem;
}

.pharmacyLabel label {
  opacity: .65;
  transform: scale(.85) translateY(-.5rem) translateX(.15rem);
  padding: 0;
}

.pharmacyLabel .preferredLabel {
  opacity: unset;
  transform: unset;
  padding: 0;
}

.pharmacyResultsContainer {
  padding-left: 35px;
  max-height: 435px;
  margin-top: 10px;
  overflow-y: hidden;
}

.pharmacyResultsContainer:hover {
  overflow-y: auto;
}

.pharmacyResultsContainer span {
  color: white;
  display: block;
}

.selectedPharmacy {
  background-color: #7c93d7;
}


.pharmacySearchButton button {
  background-color: #233362 !important;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
  font-family: Newake;
  text-transform: uppercase;
}

.preferredLabel {
  margin-left: 20px; 
  margin-right: 10px;
}

.showValidationError {
  display: block;
  margin-left: 10px;
}

.selectedIcon {
  padding: 0;
}

/* needed so places api autocomplete results show up when placed on off canvas */
:global(.pac-container) {
  z-index: 100000;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .selectablePharmacy {
    width: 325px;
  }
}