.bg {
  background-color: #1c48ab;
  margin-bottom: 50px;
}

.inverse {
  color: #ffffff;
}

.imgrow {
  margin-top: 50px;
  margin-bottom: 50px;
}

.row {
  margin-top: 30px;
  margin-bottom: 50px;
}
