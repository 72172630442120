.yourplan {
  font-family: Newake;
  font-size: 13px;
}

.plantop {
  margin-top: 50px;
  margin-bottom: 12px;
}

.planheader {
  border-top: solid 1px #dbdbdb;
}

.planintrocell {
  margin-top: 30px;
  margin-bottom: 50px;
}

.header {
  margin-left: 20px;
}

.planintro {
  font-size: 26px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: -0.29px;
  text-align: center;
}

.planbio {
  margin: 30px 40px 0;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: -0.2px;
  text-align: center;
  color: #555;
}

.instructions {
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.2px;
  margin: 10px 20px;
}

.teasers {
  margin: 20px 0;
}

.teasertitle {
  margin: 10px 0 10px;
}

.teasericon {
  margin: 5px 0 15px;
}

.teaser { 
  height: 220px;
  margin: 20px 20px 20px 0;
  width: 180px;
}

.breadcrumb {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #dbdbdb;
}

.breadcrumbActive {
  color: #1c48ab;
}

.breadcrumbIcon {
  color: #dbdbdb;
}

.breadcrumbComplete {
  color: #00ad53;
}

.breadcrumbNav {
  cursor: pointer;
}

.teaserCarouselWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 100%;
  margin: 0 15px;
}

/* react-elastic-carousel overrides */
:global(.rec.rec-slider-container) {
  overflow: visible;
}

:global(.rec.rec-arrow:focus),
:global(.rec.rec-arrow:hover) {
  background-color: transparent !important;
  box-shadow: none !important;
  color: transparent !important;
}

:global(.rec.rec-arrow) {
  z-index: 1000;
  background-color: transparent;
  box-shadow: none;
  height: 250px;
  width: 70px;
  color: transparent;
}

:global(.rec.rec-arrow-left) {
  margin-left: -25px;
}

:global(.rec.rec-dot_active) {
  background-color: #1c48ab;
  box-shadow: 0 0 1px 3px #1c48ab;
}

:global(.rec.rec-carousel-wrapper) {
  overflow: hidden;
}