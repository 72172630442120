.changer {
  position:fixed;
	bottom:40px;
	left:20px;
  border: 1px solid black;
  border-radius: 8px;
  background-color: #1c48ab;
}

.current {
  color: white;
  text-decoration: none;
  font-size: 12px;
}

.current:hover {
  color: white;
  text-decoration: none;
}

.dropdown {
  margin-left: 10px;
}

.cancel {
}