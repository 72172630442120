.button {
  height: 50px;
  min-width: 150px;
  border-radius: 10px;
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 19px;
  /* padding: 19px 65px 19px 66px; */
}

.buttonAction {
  font-size: 22px;
  border: none;
  background-color: #fd9f01;
  color: white;
}

.buttonPrimary {
  border: none;
  background-color: #fd9f01;
  color: white;
}
.buttonPrimary:disabled {
  background-color: #dbdbdb;
}
.buttonPrimary:hover {
  color: inherit;
  color: white;
}

.buttonSecondary {
  background-color: white;
  color: #fd9f01;
  border-color:  #fd9f01;
  border-width: 2px;
  border-style: solid;
}

.buttonSecondary:hover {
  color: #fd9f01;
}

.iconButton {
  border: none;
  background: none;
  margin: 0px;
  padding: 0px;
}

.disabledPrimary {
  color: #999999
}

.disabledSecondary {
  cursor: not-allowed;
  color: #999999;
  border-color:  #999999;
}

.disabledSecondary:hover {
  color: #999999;
}


.add {
  color: #fd9f01;
}

.edit {
  color: #000
}

.save {
  color: #000
}

.delete {
  color: #ff4d44
}

.cancel {
  color: #fd9f01
}

.search {
  color: #fd9f01;
}

.check { 
  color: #fd9f01;
}

.close {
  color: #fd9f01;
}

.closeinverse {
  color: #fff;
}

.clipboard {
  margin-left: 8px;
  color: #000
}

.clipboardinverse {
  margin-left: 8px;
  color: #fff;
}