.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #3a4153;
  outline: none;
  transition: border .24s ease-in-out;
}

.dropzone:focus {
  border-color: #00B1FF;
  color: #00B1FF;
}

.dropzone:hover {
  border-color: #fd9f01;
  color: #fd9f01;
}

.dropzone.disabled {
  opacity: 0.6;
}

.pager {
  width: 100%;
  border-radius: 0 0 8px 0;
  padding: 20px 0 0 0;
}

.leftAligned {
  text-align: left;
}

.surveyAnswerLabel {
  padding-left: 10px;
}

.surveyContainer {
  text-align: center;
  padding: 0;
}

.surveyQuestionContainer {
  margin: auto;
}

.surveyQuestionPrevious {
  margin-right: 300px;
}

.surveyQuestionButtons {
  margin: auto;
  padding: 25px 25% 0 25%;
}

.surveyQuestionButtons button {
  display: initial !important;
}

.surveyQuestionAnswersContainer {
  margin-top: 20px;
  text-align: center;
  display: inline-block;
}

.offCanvas {
  width: 35%;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  overflow-y: auto;
  overflow-x: hidden;
}

.offCanvas35 {
  width: 35%;
}

.offCanvasBody {
  padding: 0;
  overflow-y: unset;
}

.closeOffCanvas {
  font-size: 24px;
  text-decoration: none;
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 0;
  color: #999999;
  z-index: 7000;
}

.closeOffCanvas:focus {
  box-shadow: none;
}

.closeOffCanvasWhiteHover:hover {
  color: white;
}

.offCanvasDefaultHeader {
  background-color: #1c48ab;
  color: #ffffff;
}

.dateRangePresets {
  padding-top: 5px;
  background-color: #fff;
  margin-right: 15px;
}

.dateRangePresets button {
  margin: 3px 0;
  background-color: #f2f2f2;
  color: #3d91ff;
  padding-top: 2px;
  padding-bottom: 2px;
  border: none;
}

.dateRangePresets button:hover {
  background-color: #3d91ff;
}

.dateRangeClose {
  bottom: 0;
  position: absolute;
  width: 170px;
}

.dateRangeClose button {
  background-color: #fd9f01;
  color: white;
}

/* iPad */
@media only screen and (max-width: 820px) { 
  .surveyQuestionPrevious {
    margin-right: 150px;
  }
}

.dpcHeaderMap {
  height: 300px;
  padding-left: 0;
  padding-right: 0; 
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .surveyQuestionButtons {
    padding: 25px 0 0 0;
  }

  .surveyQuestionButtons button {
    min-width: 120px;
  }

  .dateRangePresets {
    margin-left: 15px;
  }

  .dateRangeClose {
    width: 98%;
    height: 50px;
  }

  .offCanvas {
    width: 100%;
  }

  .dpcHeaderMap {
    height: 200px;
  }
}