.success {
  color: #00ad53;
}

.error {
  color: #ff4d44;
}

.info {
  color: #1c48ab;
}