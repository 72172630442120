img.lg{
  width: 339px;
  height: 66px;
  object-fit: contain;
}

img.sm{
  width: 127px;
  height: 27.3px;
  margin: 20px auto 17.7px auto;
  object-fit: contain;
}
