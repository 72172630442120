.showValidationError {
  display: block;
}

.adminCheckBox {
  display: none;
}

.adminCheckBoxLabel {
  padding-right: 10px
}

.emailDisplay {
  height: 100%;
  align-items: center;
  display: flex;
}

.cancelButtonSpacer {
  margin-top: 17px
}