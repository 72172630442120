.card {
  padding: 30px;
}

.cardmargin {
  padding: 0px !important;
  margin: 30px 30px 30px 0;
  padding: 30px 0 30px 30px;
  border-radius: 10px;
  border: solid 1px #dedede;
  background-color: #ffffff;
  max-width: 400px;
}

.icon > svg {
  color: #00b1ff;
  margin: 20px 0 20px;
  font-size: 22px;
}

h1 {
  font-size: 22px;
  color: #383f51;
  line-height: 26px;
  letter-spacing: 0px;
  margin: 0px;
}

.detail {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0px;
  color: #72798a;
}

.button {
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
}

@media (max-width: 600px) {
  .card {
    height: auto;
  }
  h1 {
    margin: 13px 0px !important;
  }
}
@media (max-width: 992px) {
  .card {
    height: auto;
  }
}
@media (max-width: 1134px) {
  .card {
    height: auto;
  }
}