.detailheader {
  background-color: #1c48ab;
  color: #ffffff;
  padding: 100px 60px 150px; 
}

.detailHeaderSmall {
  background-color: #1c48ab;
  color: #ffffff;
  padding: 0 0 80px; 
}

.hospitaldetail {
  margin: -50px auto 80px auto;
  border-radius: 8px;
  padding-bottom: 40px;
  border: solid 1px #dedede;
  background-color: #fff;
  max-width: 1050px;
}

.hospitaldetailimage {
  border-radius: 8px;
  padding: 0px;
  margin: -40px auto auto 20px;
}

.hospitaldetailimagecondensed {
  border-radius: 8px;
  padding: 0px;
  margin: -20px auto auto 30px;
}

.detailscore { 
  margin-top: 20px;
}

.detaildivider {
  border-top: 1px solid #f1f1f1;
  margin: 20px auto 40px auto;
}

.detailcontent {
  margin-left: 90px;
}

.detailcontentcondensed {
  margin-left: 20px;
}

.detailaddress {
  margin: 40px auto 20px auto;
  font-size: 18px;
}

.icon { 
  color: #00b1ff;
}

@media screen and (max-width: 600px) {
  .detailheader {
    padding: 60px 30px 80px; 
  }

  .hospitaldetail {
    max-width: 320px;
  }

  .hospitaldetailimage {
    margin: -1px 0 0 -12px;
    border-radius: 8px 8px 0 0 ;
  }

  .detailcontent {
    margin-left: 10px;
  }

  .detailaddress {
    margin: 0;
  }
}