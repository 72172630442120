.actionLink { 
  font-family: Newake;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: -0.1px;
  color: #fd9f01;
  text-align: left;
  text-decoration: none;
  padding-top: 10px;
  margin: 0;
}

.links {
  padding: 0;
}

.links a {
  border-right: 1px #dedede solid;
  border-radius: 0;
}

.cancelSubmitButton {
  margin-top: 20px;
}

.cancelSubmitButton button {
  width: inherit;
}

.cancelModalBody {
  padding: 25px;
}