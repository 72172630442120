.cardContainer {
  margin: 0 175px 0 225px;
}

.closeicon {
  color: #999999;
}

.telephoneLink {
  text-decoration: none;
}

/* MOBILE */
@media only screen and (max-width: 600px) {
  .cardContainer {
    margin: auto;
  }
}