.float {
  position:fixed;
	bottom:40px;
	right:40px;
}

.buttonicon {
  color: #fff
}

.closeicon {
  color: #999999;
}

.modalicon {
  color: #699dff;
}

.helpsection {
  margin: 10px 10px;
}

.copy {
  font-size: 18px;
}

.helpcomponent {
  margin: 45px 0;
}