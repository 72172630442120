.messages {
  margin-top: 13px;
  color: #00B1FF;
}

.noMessages {
  margin-top: 13px;
  color: #72798A;
}

.hasMessages {
  margin-left: -0.75em;
  padding-left: .5em;
  color: #FF3A3A;
  border-radius: 50%;
  background-color:  #FF3A3A;
  border-color: white;
  border-width: .1em;
  border-style: solid;
  line-height: 0.40em;
  width: 0.62em;
}